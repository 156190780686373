import { InvoiceKind } from "@/@types/invoices";
import {
  URL_DASHBOARD,
  URL_CASH_FLOW,
  URL_CONTACTS,
  URL_INVOICES,
  URL_TAXES,
  URL_PRODUCTS,
  URL_SERVICES,
  URL_COMPANY,
} from "@/constants/urls";
import { cn } from "@/lib/utils";
import {
  ChartDonut,
  Users,
  File,
  Package,
  Receipt,
  SquaresFour,
  Scroll,
} from "@phosphor-icons/react";
import { Link, useLocation } from "react-router";

const NAVIGATION = [
  {
    label: "Painel",
    pathname: URL_DASHBOARD,
    icon: SquaresFour,
  },
  {
    label: "Finanças",
    pathname: URL_CASH_FLOW,
    icon: ChartDonut,
    mobile: true,
  },
  {
    label: "Impostos",
    pathname: URL_TAXES,
    icon: Receipt,
  },
  {
    label: "Notas",
    pathname: `${URL_INVOICES}?model=${InvoiceKind.SERVICE}`,
    icon: Scroll,
  },
];

type Props = {
  companyId: string;
};

export function NavigationWebview(inProps: Props) {
  const { companyId } = inProps;
  const { pathname } = useLocation();

  return (
    <div className="flex justify-center fixed left-0 bottom-0 z-20 bg-background px-5 py-3 overflow-x-auto w-screen">
      <nav className="flex items-center justify-between whitespace-nowrap py-3 h-16">
        <ul className="flex gap-8">
          {NAVIGATION.map((navigation) => {
            const baseUrl = `${URL_COMPANY}/${companyId}`;

            const isActive = Array.isArray(navigation.pathname)
              ? navigation.pathname.some((path) =>
                  pathname.includes(`${baseUrl}${path}`)
                )
              : pathname.includes(`${baseUrl}${navigation.pathname}`);

            const href = `${baseUrl}${
              Array.isArray(navigation.pathname)
                ? navigation.pathname[0]
                : navigation.pathname
            }`;

            return (
              <Link
                key={navigation.label}
                to={href}
                className="flex flex-col items-center gap-1"
              >
                <navigation.icon
                  weight={isActive ? "fill" : "light"}
                  className={cn("text-2xl", {
                    "text-blue-700 dark:text-blue-600": isActive,
                    "text-gray-400": !isActive,
                  })}
                />
                <span
                  className={cn(
                    "text-xs font-regular text-center text-gray-400 dark:text-gray-700 hover:text-blue dark:hover:text-blue-400",
                    {
                      "text-blue dark:text-blue-600 font-bold": isActive,
                    }
                  )}
                >
                  {navigation.label}
                </span>
              </Link>
            );
          })}
        </ul>
      </nav>
    </div>
  );
}
