import { Adapter } from "@/@types";

import { Address, City, State } from "@/@types/address";

export class AddressService {
  constructor(private readonly http: Adapter) {}

  async getStates(): Promise<State[]> {
    return this.http.get(`/v1/ibge/states`).then((response) => response.data);
  }

  async getCities(state: string): Promise<City[]> {
    return this.http
      .get(`/v1/ibge/${state}/cities`)
      .then((response) => response.data);
  }

  async getAddress(postalCode: string): Promise<Address> {
    return this.http
      .get(`/v1/ibge/${postalCode}/address`)
      .then((response) => response.data);
  }
}
