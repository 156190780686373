import useIsMobile from "@/modules/webview/useIsMobile";
import useMobileReadyFlag from "@/modules/webview/useMobileReadyFlag";

import { Outlet } from "react-router";

export function AuthComponent() {
  const isMobileReady = useMobileReadyFlag();
  const isMobile = useIsMobile();

  if (isMobile && isMobileReady) {
    return (
      <main className="flex flex-col min-h-screen md:hidden">
        <Outlet />
      </main>
    );
  }

  return (
    <main className="h-screen overflow-hidden flex">
      <aside className="flex flex-col items-center justify-center py-16 px-4 w-full mx-auto">
        <div className="max-w-[420px]">
          <Outlet />
        </div>
      </aside>
      <aside className="hidden lg:flex relative bg-gray-50 h-screen min-w-[527px] flex-col overflow-hidden">
        <div className="h-full">
          <img
            src="/images/signin.png"
            width={527}
            height={1024}
            alt="Duas pessoas olhando para o celular"
            className="w-full h-full object-cover"
          />
        </div>
      </aside>
    </main>
  );
}
