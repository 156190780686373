import { Adapter } from "@/@types";
import { Attachment, CreateAttachmentPayload } from "@/@types/attachments";

export class AttachmentService {
  constructor(private readonly http: Adapter) {}

  async getAttachments(
    companyId: string,
    { receivable_id, bill_id }: { receivable_id?: string; bill_id?: string }
  ): Promise<Attachment[]> {
    return this.http
      .get(`/v1/companies/${companyId}/attachments`, {
        params: {
          receivable_id,
          bill_id,
        },
      })
      .then((response) => response.data);
  }

  async getAttachment(
    companyId: string,
    attachmentId: string
  ): Promise<Attachment> {
    return this.http
      .get(`/v1/companies/${companyId}/attachments/${attachmentId}`)
      .then((response) => response.data);
  }

  async deleteAttachment(
    companyId: string,
    attachmentId: string
  ): Promise<string> {
    return this.http
      .delete(`/v1/companies/${companyId}/attachments/${attachmentId}`)
      .then((response) => response.data);
  }

  async createAttachment(
    companyId: string,
    payload: CreateAttachmentPayload
  ): Promise<Attachment> {
    return this.http
      .postForm(`/v1/companies/${companyId}/attachments`, payload)
      .then((response) => response.data);
  }
}
