import * as React from "react";
import { toPattern } from "vanilla-masker";

import { cn } from "@/lib/utils";
import { Spinner } from "@phosphor-icons/react";

export interface InputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  mask?: string[];
  loading?: boolean;
  startAdornment?: React.ReactNode;
  startAdornmentClassName?: string;
  endAdornment?: React.ReactNode;
  endAdornmentClassName?: string;
}

export function unMask(value: string | null): string {
  if (!value) return "";

  return value.replace(/\W/g, "");
}

function applyMask(patterns: string | string[], value?: any) {
  if (!value) return "";

  if (typeof patterns === "string") {
    return toPattern(value, patterns);
  }

  return toPattern(
    value,
    patterns.reduce(
      (memo: string, pattern: string) =>
        value.length <= unMask(memo).length ? memo : pattern,
      patterns[0]
    )
  );
}

function isMaskFilled(patterns: string[], value: string) {
  const maskLength = Math.max(...patterns.map((pattern) => pattern.length));

  return value.length > maskLength;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type,
      value,
      mask,
      onChange,
      loading,
      disabled,
      startAdornment,
      startAdornmentClassName,
      endAdornment,
      endAdornmentClassName,
      ...props
    },
    ref
  ) => {
    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      if (onChange) {
        const value = event.target.value;

        if (mask && isMaskFilled(mask, value.trim())) return;

        onChange({
          ...event,
          target: {
            ...event.target,
            value: mask ? unMask(value) : value,
          },
        });
      }
    }

    const maskedValue = mask ? applyMask(mask, value) : value;

    return (
      <div className="relative flex items-center">
        <input
          type={type}
          className={cn(
            `
              flex 
              h-12 
              w-full 
              rounded-md 
              border 
              border-input 
              transition-all 
              outline-none 
              bg-background 
              px-3 py-2 
              text-sm 
              file:border-0 
              file:bg-transparent 
              file:text-sm 
              file:font-normal 
              placeholder:text-muted-foreground
              hover:border-blue 
              focus-visible:border-blue 
              disabled:cursor-not-allowed 
              disabled:opacity-50 
              group-invalid:border-red-400
            `,
            className,
            {
              "pr-11": !!endAdornment || loading,
            }
          )}
          ref={ref}
          disabled={disabled || loading}
          value={maskedValue}
          onChange={handleChange}
          {...props}
          aria-label={props["aria-label"] || props.name}
        />
        {(endAdornment || loading) && (
          <div
            className={cn(
              "absolute right-4 top-[50%] translate-y-[-50%]",
              endAdornmentClassName
            )}
          >
            {loading ? (
              <Spinner size={20} className="animate-spin fill-blue-700" />
            ) : (
              endAdornment
            )}
          </div>
        )}
      </div>
    );
  }
);
Input.displayName = "Input";

export { Input };
