import { Adapter } from "@/@types";
import { CreateSubscriptionPayload, Subscription } from "@/@types/subscription";

export class SubscriptionService {
  constructor(private readonly http: Adapter) {}

  async createSubscription(
    payload: CreateSubscriptionPayload
  ): Promise<Subscription> {
    return this.http
      .post(`/v1/subscriptions`, payload)
      .then((response) => response.data);
  }
}
