import {
  DialogCloseProps,
  DialogContentProps,
  DialogDescriptionProps,
  DialogOverlayProps,
  DialogProps,
  DialogTitleProps,
  DialogTriggerProps,
} from "@radix-ui/react-dialog";

import useIsMobile from "@/modules/webview/useIsMobile";
import useMobileReadyFlag from "@/modules/webview/useMobileReadyFlag";

import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerOverlay,
  DrawerTitle,
  DrawerTrigger,
  DrawerHeader,
  DrawerClose,
} from "./drawer";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogOverlay,
  DialogTitle,
  DialogTrigger,
  DialogHeader,
  DialogClose,
} from "./dialog";
import { transition } from "@/constants/theme";
import { useState } from "react";

function Float({ defaultOpen = true, onOpenChange, ...rest }: DialogProps) {
  const [open, setOpen] = useState(defaultOpen);

  const isMobile = useIsMobile();
  const isMobileReady = useMobileReadyFlag();

  function handleOpenChange(open: boolean) {
    setOpen(false);

    if (onOpenChange) {
      setTimeout(() => {
        onOpenChange(open);
      }, transition.dialog);
    }
  }

  if (isMobile && isMobileReady) {
    return <Drawer open={open} onOpenChange={handleOpenChange} {...rest} />;
  }

  return <Dialog open={open} onOpenChange={handleOpenChange} {...rest} />;
}

function FloatClose(inProps: DialogCloseProps) {
  const isMobile = useIsMobile();
  const isMobileReady = useMobileReadyFlag();

  if (isMobile && isMobileReady) {
    return <DrawerClose {...inProps} />;
  }

  return <DialogClose {...inProps} />;
}

function FloatHeader(inProps: any) {
  const isMobile = useIsMobile();
  const isMobileReady = useMobileReadyFlag();

  if (isMobile && isMobileReady) {
    return <DrawerHeader {...inProps} />;
  }

  return <DialogHeader {...inProps} />;
}

function FloatOverlay(inProps: DialogOverlayProps) {
  const isMobile = useIsMobile();
  const isMobileReady = useMobileReadyFlag();

  if (isMobile && isMobileReady) {
    return <DrawerOverlay {...inProps} />;
  }

  return <DialogOverlay {...inProps} />;
}

function FloatContent(inProps: DialogContentProps) {
  const isMobile = useIsMobile();
  const isMobileReady = useMobileReadyFlag();

  if (isMobile && isMobileReady) {
    return (
      <DrawerContent {...inProps} onOpenAutoFocus={(e) => e.preventDefault()} />
    );
  }

  return (
    <DialogContent {...inProps} onOpenAutoFocus={(e) => e.preventDefault()} />
  );
}

function FloatTitle(inProps: DialogTitleProps) {
  const isMobile = useIsMobile();
  const isMobileReady = useMobileReadyFlag();

  if (isMobile && isMobileReady) {
    return <DrawerTitle {...inProps} />;
  }

  return <DialogTitle {...inProps} />;
}

function FloatDescription(inProps: DialogDescriptionProps) {
  const isMobile = useIsMobile();
  const isMobileReady = useMobileReadyFlag();

  if (isMobile && isMobileReady) {
    return <DrawerDescription {...inProps} />;
  }

  return <DialogDescription {...inProps} />;
}

function FloatTrigger(inProps: DialogTriggerProps) {
  const isMobile = useIsMobile();
  const isMobileReady = useMobileReadyFlag();

  if (isMobile && isMobileReady) {
    return <DrawerTrigger {...inProps} />;
  }

  return <DialogTrigger {...inProps} />;
}

export {
  Float,
  FloatClose,
  FloatOverlay,
  FloatHeader,
  FloatContent,
  FloatTitle,
  FloatDescription,
  FloatTrigger,
};
