import { Adapter } from "@/@types";
import { Certificate, CreateCertificatePayload } from "@/@types/certificate";

export class CertificateService {
  constructor(private readonly http: Adapter) {}

  async getCertificate(companyId: string): Promise<Certificate> {
    return this.http
      .get(`/v1/companies/${companyId}/certificate`)
      .then((response) => response.data);
  }

  async createCertificate(
    companyId: string,
    payload: CreateCertificatePayload
  ) {
    return this.http.postForm(
      `/v1/companies/${companyId}/certificate`,
      payload
    );
  }

  async deleteCertificate(companyId: string) {
    return this.http.delete(`/v1/companies/${companyId}/certificate`);
  }
}
