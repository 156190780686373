import { forwardRef, useMemo } from "react";
import { useMutation } from "@tanstack/react-query";

import { useService } from "@/services";
import { useNaturalPerson } from "@/modules/persons";
import { LegalPerson, LegalPersonSituation } from "@/@types/person";
import { formatCompanyDocumentNumber } from "@/modules/formatters";
import { Autocomplete, AutocompleteProps, unMask } from "@/components/ui";

type Props = {
  naturalDocumentNumber: string;
  onLegalPersonChange?: (legalPerson?: LegalPerson) => void;
} & Omit<AutocompleteProps, "options">;

export const OwnerCompaniesContainer = forwardRef<HTMLDivElement, Props>(
  function OwnerCompaniesContainer(inProps, ref) {
    const { naturalDocumentNumber, onValueChange, onLegalPersonChange } =
      inProps;

    const { company } = useService();

    const { mutateAsync: getCompany, isPending } = useMutation({
      mutationFn: (documentNumber: string) => company.getInfo(documentNumber),
    });

    const { naturalPerson, isLoading } = useNaturalPerson(
      naturalDocumentNumber
    );

    const companies = useMemo(() => {
      if (!naturalPerson || !naturalPerson.legal_entities) {
        return [];
      }

      return naturalPerson.legal_entities
        .filter(
          (legalPerson) => legalPerson.situation === LegalPersonSituation.ATIVA
        )
        .map((legalPerson) => ({
          label: `${
            legalPerson.trade_name || legalPerson.name
          } (${formatCompanyDocumentNumber(legalPerson.document_number)})`,
          value: legalPerson.document_number,
        }));
    }, [naturalPerson]);

    async function handleChange(value: string) {
      const unMasked = unMask(value);

      onValueChange?.(unMasked);

      if (!naturalPerson?.legal_entities && unMasked.length === 14) {
        const company = await getCompany(unMasked);

        return onLegalPersonChange?.({
          name: company.name,
          document_number: company.document_number,
          trade_name: company.trade_name || "",
          situation: LegalPersonSituation.ATIVA,
          verified: false,
        });
      }

      const legalPerson = naturalPerson?.legal_entities?.find((legalPerson) => {
        return legalPerson.document_number === value;
      });

      if (legalPerson) {
        onLegalPersonChange?.({
          ...legalPerson,
          verified: true,
        });
      }
    }

    return (
      <Autocomplete
        ref={ref}
        freeSolo
        shouldUseValueOnLabel
        mask={["99.999.999/9999-99"]}
        options={companies}
        loading={isLoading || isPending}
        {...inProps}
        onValueChange={handleChange}
      />
    );
  }
);
