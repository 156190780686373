/* eslint-disable @next/next/no-img-element */
"use client";

import React, { useMemo } from "react";

import { cn } from "@/lib/utils";
import { Command as CommandPrimitive } from "cmdk";
import { useVirtualizer } from "@tanstack/react-virtual";
import { PopoverAnchor } from "@radix-ui/react-popover";
import {
  CommandEmpty,
  CommandGroup,
  CommandItem,
  CommandList,
} from "./command";
import { Skeleton } from "./skeleton";
import { CaretDown } from "@phosphor-icons/react";
import { Separator } from "./separator";
import { Popover, PopoverContent } from "./popover";
import { Input } from "./input";

type Option = {
  label: string;
  value: string;
  searchTerm?: string | null;
  parent?: boolean;
};

export type AutocompleteProps = {
  name?: string;
  mask?: string[];
  placeholder?: string;
  empty?: React.ReactNode;
  options: Option[];
  value: string;
  loading?: boolean;
  shouldUseValueOnLabel?: boolean;
  disabled?: boolean;
  action?: React.ReactNode;
  onValueChange: (value: string) => void;
  onEnter?: () => void;
  freeSolo?: boolean;
  tree?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

function AutocompleteList({
  filtered,
  onSelect,
  tree,
}: {
  filtered: Option[];
  onSelect: (option: Option) => void;
  tree?: boolean;
}) {
  const parentRef = React.useRef<HTMLDivElement>(null);

  const rowVirtualizer = useVirtualizer({
    overscan: 5,
    count: filtered?.length || 0,
    getScrollElement: () => parentRef.current,
    estimateSize: () => 35,
    getItemKey: (index) => filtered?.[index]?.value || "",
  });

  const items = rowVirtualizer.getVirtualItems() || [];

  return (
    <CommandList ref={parentRef} className="overflow-auto p-[4px]">
      <CommandGroup
        className="w-full overflow-auto relative"
        style={{
          position: "relative",
          height: `${rowVirtualizer.getTotalSize()}px`,
        }}
      >
        <div
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            transform: `translateY(${items[0]?.start ?? 0}px)`,
          }}
        >
          {items?.map((virtualRow) => (
            <CommandItem
              key={virtualRow.key}
              data-index={virtualRow.index}
              ref={(el) => rowVirtualizer.measureElement(el)}
              disabled={!!filtered?.[virtualRow.index]?.parent}
              value={filtered?.[virtualRow.index]?.label}
              onMouseDown={(event) => {
                event.preventDefault();
                event.stopPropagation();
              }}
              onSelect={() =>
                filtered && onSelect(filtered?.[virtualRow.index])
              }
              className={cn(
                "flex w-full items-center gap-2 cursor-pointer",
                {
                  "!text-slate-600 !text-xs font-medium !py-2.5":
                    !!filtered?.[virtualRow.index]?.parent,
                },
                {
                  "ml-3": !filtered?.[virtualRow.index]?.parent && tree,
                }
              )}
            >
              {filtered?.[virtualRow.index]?.label}
            </CommandItem>
          ))}
        </div>
      </CommandGroup>
    </CommandList>
  );
}

const Autocomplete = React.forwardRef<HTMLDivElement, AutocompleteProps>(
  (
    {
      name,
      placeholder = "Selecione",
      shouldUseValueOnLabel = false,
      empty,
      options,
      loading,
      disabled,
      value,
      onValueChange,
      onBlur,
      className,
      action,
      freeSolo = false,
      tree,
      mask,
      "aria-label": ariaLabel,
      onEnter,
    },
    ref
  ) => {
    const inputRef = React.useRef<HTMLInputElement>(null);
    const initialOptions = React.useRef(options);

    const [isOpen, setOpen] = React.useState(false);
    const [inputValue, setInputValue] = React.useState<string | null>(
      options.find((option) => option.value === value)?.label || null
    );

    const selected = React.useMemo(() => {
      return options.find((option) => option.value === value) as Option;
    }, [options, value]);

    const [filtered, setFiltered] = React.useState<Option[] | null>(null);
    const [isPending, startTransition] = React.useTransition();

    const shouldUpdate = options.length !== initialOptions.current.length;

    React.useEffect(() => {
      if ((filtered === null && options.length > 0) || shouldUpdate) {
        setFiltered(options);
      }
    }, [options, filtered, shouldUpdate]);

    React.useEffect(() => {
      if (inputValue === null && selected) {
        setInputValue(selected.label);
      }
    }, [selected, inputValue]);

    const handleKeyDown = React.useCallback(
      (event: React.KeyboardEvent<HTMLDivElement>) => {
        const input = inputRef.current;
        if (!input) {
          return;
        }

        // Keep the options displayed when the user is typing
        if (!isOpen && !freeSolo) {
          setOpen(true);
        }

        // This is not a default behaviour of the <input /> field
        if (event.key === "Enter" && input.value !== "") {
          onEnter?.();

          if (freeSolo) {
            input.blur();
            return;
          }

          const optionToSelect = options.find(
            (option) => option.label === input.value
          );
          if (optionToSelect) {
            onValueChange?.(optionToSelect.value);
          }
        }
        if (event.key === "Escape") {
          input.blur();
        }
      },
      [options, onValueChange, isOpen, freeSolo, onEnter]
    );

    const handleBlur = React.useCallback(
      (event: React.FocusEvent<HTMLDivElement>) => {
        setOpen(false);
        if (!freeSolo) {
          setInputValue(selected?.label);
        }
        onBlur?.(event);
      },
      [selected, onBlur, freeSolo]
    );

    const handleSelectOption = React.useCallback(
      (selectedOption: Option) => {
        if (shouldUseValueOnLabel) {
          setInputValue(selectedOption.value);
        } else {
          setInputValue(selectedOption.label);
        }
        setOpen(false);
        onValueChange?.(selectedOption.value);
        // This is a hack to prevent the input from being focused after the user selects an option
        // We can call this hack: "The next tick"
        setTimeout(() => {
          inputRef?.current?.blur();
        }, 0);
      },
      [onValueChange, shouldUseValueOnLabel]
    );

    const handleFocus = React.useCallback(() => {
      setOpen(true);
    }, []);

    const handleValueChange = React.useCallback(
      (value: string) => {
        if (loading) return;

        setInputValue(value);

        if (freeSolo) {
          onValueChange(value);
        }

        startTransition(() => {
          setFiltered(
            options.filter((option) =>
              (option.searchTerm || option.label)
                .toLowerCase()
                .includes(value.toLowerCase())
            )
          );
        });
      },
      [options, freeSolo, loading, onValueChange]
    );

    const shouldPopoverContent = useMemo(() => {
      if (freeSolo && filtered?.length === 0) {
        return false;
      }

      return true;
    }, [freeSolo, filtered]);

    return (
      <Popover open={isOpen} modal>
        <CommandPrimitive
          ref={ref}
          onKeyDown={handleKeyDown}
          shouldFilter={false}
          className={className}
        >
          <PopoverAnchor className="relative">
            <Input
              role="combobox"
              mask={mask}
              ref={inputRef}
              aria-label={ariaLabel || name}
              value={inputValue || ""}
              onChange={(e) => handleValueChange(e.currentTarget.value)}
              onFocus={handleFocus}
              onBlur={handleBlur}
              placeholder={placeholder}
              disabled={disabled || loading}
              className="text-sm pr-8"
            />
            {!freeSolo && (
              <div className="absolute right-3 top-1/2 transform -translate-y-1/2 pointer-events-none">
                <CaretDown size={16} />
              </div>
            )}
          </PopoverAnchor>
          {shouldPopoverContent && (
            <PopoverContent
              className="p-0"
              onOpenAutoFocus={(e) => e.preventDefault()}
            >
              {loading || isPending ? (
                <CommandPrimitive.Loading>
                  <div className="p-1">
                    <Skeleton className="h-8 w-full" />
                  </div>
                </CommandPrimitive.Loading>
              ) : null}
              {options.length > 0 && !loading ? (
                <AutocompleteList
                  tree={tree}
                  filtered={filtered || []}
                  onSelect={handleSelectOption}
                />
              ) : null}

              {!loading && !freeSolo ? (
                <CommandEmpty className="select-none rounded-sm px-2 py-3 flex flex-col items-center justify-center gap-3">
                  <img src="/images/box.png" width={68} height={81} alt="Box" />
                  <span className="text-center text-sm text-muted-foreground">
                    {empty}
                  </span>
                </CommandEmpty>
              ) : null}
              {action && (
                <>
                  <Separator className="mt-1" />
                  <div className="p-1">{action}</div>
                </>
              )}
            </PopoverContent>
          )}
        </CommandPrimitive>
      </Popover>
    );
  }
);
Autocomplete.displayName = "Autocomplete";

export { Autocomplete };
