import { useState } from "react";
import { Link, useLocation } from "react-router";

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui";
import { URL_COMPANY } from "@/constants/urls";
import { cn } from "@/lib/utils";

type Props = {
  label: string;
  pathname: string | string[];
  companyId: string;
  inside?: Pick<Props, "label" | "pathname">[];
};

export function MenuItem(inProps: Props) {
  const [open, setOpen] = useState(false);

  const { label, pathname, companyId, inside } = inProps;
  const { pathname: currentPathname } = useLocation();

  const baseUrl = `${URL_COMPANY}/${companyId}`;

  const isActive = Array.isArray(pathname)
    ? pathname.find((path) => `${baseUrl}${path}`.includes(currentPathname))
    : `${baseUrl}${pathname}`.includes(currentPathname);

  const href = `${baseUrl}${Array.isArray(pathname) ? pathname[0] : pathname}`;

  const linkClassName = cn(
    "text-sm font-medium text-center text-gray-400 dark:text-gray-700 rounded-[8px] py-1.5 px-3 transition-all hover:bg-gray-50 hover:text-blue dark:hover:text-blue-400 focus-visible:outline-none",
    {
      "text-blue dark:text-blue-400 bg-gray-50": isActive,
    }
  );

  if (inside) {
    return (
      <div
        className="h-full w-full flex"
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
      >
        <DropdownMenu open={open} modal={false}>
          <DropdownMenuTrigger asChild>
            <Link key={label} to={href} className={linkClassName}>
              {label}
            </Link>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            {inside.map((child) => (
              <DropdownMenuItem key={child.label} asChild>
                <Link to={`${baseUrl}${child.pathname}`}>{child.label}</Link>
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    );
  }

  return (
    <Link key={label} to={href} className={linkClassName}>
      {label}
    </Link>
  );
}
