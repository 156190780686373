import { analytics } from "@/adapters";
import { createStore } from "zustand/vanilla";

type Status = "initialized" | "loading" | "finished" | "error";

export type AuthState = {
  userId?: string;
  status?: Status;
};

export type AuthActions = {
  setUserId: (userId?: string) => void;
  setStatus: (status: Status) => void;
};

export type AuthStore = AuthState & AuthActions;

export const initialState: AuthState = {
  userId: undefined,
  status: "initialized",
};

export const initAuthStore = (): AuthState => {
  return initialState;
};

export const createAuthStore = (authState: AuthState = initialState) => {
  return createStore<AuthStore>((set) => ({
    ...authState,
    setUserId: (userId?: string) => {
      analytics.setUser(userId);
      set(() => ({ userId, status: "finished" }));
    },
    setStatus: (status: Status) => set(() => ({ status })),
  }));
};
