import { useQuery } from "@tanstack/react-query";

import { useService } from "@/services";

export function useNaturalPerson(documentNumber: string) {
  const { person } = useService();

  const { data: naturalPerson, ...rest } = useQuery({
    enabled: Boolean(documentNumber),
    queryKey: ["natural_entities", documentNumber],
    queryFn: () => person.getNaturalPerson(documentNumber),
  });

  return {
    naturalPerson,
    ...rest,
  };
}
