import { InvoiceKind } from "@/@types/invoices";
import {
  URL_DASHBOARD,
  URL_CASH_FLOW,
  URL_CONTACTS,
  URL_INVOICES,
  URL_TAXES,
  URL_PRODUCTS,
  URL_SERVICES,
  URL_CATEGORIES,
} from "@/constants/urls";
import { MenuItem } from "./menu-item";
import type { ReactNode } from "react";

const NAVIGATION = [
  {
    label: "Painel",
    pathname: URL_DASHBOARD,
  },
  {
    label: "Fluxo de caixa",
    pathname: URL_CASH_FLOW,
    children: [
      {
        label: "Categorias",
        pathname: `${URL_CATEGORIES}`,
      },
      {
        label: "Despesas",
        pathname: `${URL_CASH_FLOW}?tab=BILLS`,
      },
      {
        label: "Receitas",
        pathname: `${URL_CASH_FLOW}?tab=RECEIVABLES`,
      },
    ],
  },
  {
    label: "Contatos",
    pathname: URL_CONTACTS,
  },
  {
    label: "Notas Fiscais",
    pathname: `${URL_INVOICES}?model=${InvoiceKind.SERVICE}`,
    children: [
      {
        label: "NF de Serviço",
        pathname: `${URL_INVOICES}?model=${InvoiceKind.SERVICE}`,
      },
      {
        label: "NF de Produto",
        pathname: `${URL_INVOICES}?model=${InvoiceKind.PRODUCT}`,
      },
    ],
  },
  {
    label: "Impostos",
    pathname: URL_TAXES,
  },
  {
    label: "Produto e serviço",
    pathname: [URL_PRODUCTS, URL_SERVICES],
  },
];

type Props = {
  companyId: string;
  action?: ReactNode;
};

export function Menu(inProps: Props) {
  const { companyId, action } = inProps;

  return (
    <nav className="flex items-center justify-between mt-6 -ml-3 whitespace-nowrap overflow-x-auto">
      <ul className="flex gap-2">
        {NAVIGATION.map((navigation) => (
          <MenuItem
            key={navigation.label}
            label={navigation.label}
            pathname={navigation.pathname}
            inside={navigation.children}
            companyId={companyId}
          />
        ))}
      </ul>
      {action}
    </nav>
  );
}
