"use client";

import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { Check } from "lucide-react";

import { cn } from "@/lib/utils";

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      `
        peer h-5 w-5 shrink-0 rounded-[4px] border bg-gray-200 ring-offset-background transition-all 
        
        focus-visible:outline-none 
        focus-visible:ring-2 
        focus-visible:ring-blue 
        focus-visible:ring-offset-2 
        disabled:cursor-not-allowed 
        disabled:opacity-50 
        data-[state=checked]:bg-blue-700 
        data-[state=checked]:text-primary-foreground
      `,
      className
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn("flex items-center justify-center text-current")}
    >
      <Check className="h-3 w-3" aria-label="check" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
