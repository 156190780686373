"use client";

import * as React from "react";
import { Eye, EyeSlash } from "@phosphor-icons/react";

import { Input } from "./input";

export interface InputPasswordProps
  extends React.InputHTMLAttributes<HTMLInputElement> {}

const InputPassword = React.forwardRef<HTMLInputElement, InputPasswordProps>(
  ({ className, ...props }, ref) => {
    const [type, setType] = React.useState("password");

    return (
      <Input
        ref={ref}
        {...props}
        type={type}
        endAdornmentClassName="mt-1"
        endAdornment={
          <button
            type="button"
            onClick={() =>
              setType((prevState) =>
                prevState === "password" ? "text" : "password"
              )
            }
          >
            {type === "password" ? <Eye size={20} /> : <EyeSlash size={20} />}
          </button>
        }
      />
    );
  }
);
InputPassword.displayName = "InputPassword";

export { InputPassword };
