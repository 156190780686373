"use client";

import * as React from "react";
import { toCurrency } from "@/modules/number";

import { Input, InputProps } from "./input";

export interface InputAmountProps extends Omit<InputProps, "mask" | "value"> {
  value?: number | string;
  allowNegative?: boolean;
}

function inputPositiveNumberParser(value?: string) {
  return value ? parseInt(value.replace(/\D/g, "")) : 0;
}

function inputNegativeNumberParser(value?: string) {
  return value ? parseInt(value.replace(/[^-\d]/g, "")) : 0;
}

function inputNumberFormatter(value?: string | number): string {
  return value ? toCurrency(parseInt(value.toString())) : "";
}

const InputAmount = React.forwardRef<HTMLInputElement, InputAmountProps>(
  ({ className, value, onChange, allowNegative = false, ...props }, ref) => {
    function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
      if (onChange) {
        const value = event.target.value;

        onChange({
          ...event,
          target: {
            ...event.target,
            // @ts-ignore
            value: allowNegative
              ? inputNegativeNumberParser(value)
              : inputPositiveNumberParser(value),
          },
        });
      }
    }

    return (
      <Input
        ref={ref}
        value={inputNumberFormatter(value)}
        onChange={handleChange}
        {...props}
      />
    );
  }
);
InputAmount.displayName = "InputAmount";

export { InputAmount };
