import { CustomerInvoice } from "./customer-invoice";
import { ProductInvoice } from "./product-invoice";
import { ServiceInvoice } from "./service-invoice";

export enum InvoiceKind {
  SERVICE = "service",
  PRODUCT = "product",
  CUSTOMER = "customer",
}

export type Invoice = ProductInvoice | ServiceInvoice | CustomerInvoice;
