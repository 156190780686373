import * as React from "react";
import { format } from "date-fns";
import { DateRange, SelectRangeEventHandler } from "react-day-picker";

import { Button } from "./button";
import { Calendar } from "./calendar";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { ptBR } from "date-fns/locale/pt-BR";
import { cn } from "@/lib/utils";

export interface InputDateRangePickerProps
  extends Omit<React.HTMLAttributes<HTMLButtonElement>, "onChange" | "value"> {
  value?: DateRange;
  onChange: SelectRangeEventHandler;
}

const InputDateRangePicker = React.forwardRef<
  HTMLButtonElement,
  InputDateRangePickerProps
>((inProps, ref) => {
  const { value, onChange, ...rest } = inProps;

  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          ref={ref}
          {...rest}
          variant="outline"
          size="small"
          className={cn(rest.className, "capitalize")}
        >
          {value?.from ? (
            value.to ? (
              <>
                {format(value.from, "LLL dd, y", {
                  locale: ptBR,
                })}{" "}
                -{" "}
                {format(value.to, "LLL dd, y", {
                  locale: ptBR,
                })}
              </>
            ) : (
              format(value.from, "LLL dd, y", {
                locale: ptBR,
              })
            )
          ) : (
            <span>Selecione uma data</span>
          )}
        </Button>
      </PopoverTrigger>

      <PopoverContent className="p-0 w-full">
        <Calendar
          mode="range"
          selected={value}
          onSelect={onChange}
          defaultMonth={value?.from}
          numberOfMonths={2}
          classNames={{
            day: "size-8",
            cell: "size-8",
            head_cell: "w-8",
            head_row: "!gap-0",
            row: "!gap-0",
          }}
        />
      </PopoverContent>
    </Popover>
  );
});
InputDateRangePicker.displayName = "InputDateRangePicker";

export { InputDateRangePicker };
