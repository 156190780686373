import { Adapter } from "@/@types";

import { Institution } from "@/@types/institutions";

export class InstitutionsService {
  constructor(private readonly http: Adapter) {}

  async getInstitutions(): Promise<Institution[]> {
    return this.http.get(`/v1/institutions`).then((response) => response.data);
  }
}
