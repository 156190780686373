"use client";

import * as React from "react";
import { DayPicker } from "react-day-picker";
import { CaretLeft, CaretRight } from "@phosphor-icons/react";
import { ptBR } from "date-fns/locale/pt-BR";

import { cn } from "@/lib/utils";
import { buttonVariants } from "@/components/ui/button";

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn("p-3", className)}
      initialFocus={false}
      classNames={{
        months: "flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0",
        month: "space-y-4",
        caption_start: "w-full",
        caption:
          "flex justify-center pt-1 relative items-center bg-gray-50 rounded-[8px] h-9",
        caption_label: "capitalize text-sm font-medium",
        nav: "space-x-1 flex items-center",
        nav_button:
          "h-6 w-6 bg-white dark:bg-gray-100 flex items-center justify-center rounded-[6px] shadow-sm mb-1",
        nav_button_previous: "absolute left-1",
        nav_button_next: "absolute right-1",
        table: "w-full border-collapse space-y-1",
        head_row: cn("flex gap-3", classNames?.head_row),
        head_cell: cn(
          "capitalize text-muted-foreground rounded-sm w-10 font-normal text-[0.8rem]",
          classNames?.head_cell
        ),
        row: cn("flex w-full mt-2 gap-3", classNames?.row),
        cell: cn(
          "size-10 text-center text-sm p-0 relative [&:has([aria-selected].day-range-end)]:rounded-r-sm [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:rounded-l-sm last:[&:has([aria-selected])]:rounded-r-sm focus-within:relative focus-within:z-20",
          classNames?.cell
        ),
        day: cn(
          buttonVariants({ variant: "ghost" }),
          "size-10 p-0 font-normal text-gray-900 transition-all aria-selected:opacity-100",
          classNames?.day
        ),
        day_range_start: "!bg-blue-700 !text-white !rounded-sm",
        day_range_end: "!bg-blue-700 !text-white",
        day_selected: cn(
          "bg-blue-50 transition-all !text-blue !font-medium",
          classNames?.day_selected
        ),
        day_today: "bg-muted transition-all text-primary !font-medium",
        day_outside:
          "day-outside text-muted-foreground opacity-50 aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30",
        day_disabled: "text-muted-foreground opacity-50",
        day_range_middle:
          "aria-selected:bg-accent aria-selected:text-accent-foreground",
        day_hidden: "invisible",
      }}
      components={{
        IconLeft: () => <CaretLeft size={12} />,
        IconRight: () => <CaretRight size={12} />,
      }}
      locale={ptBR}
      {...props}
    />
  );
}
Calendar.displayName = "Calendar";

export { Calendar };
