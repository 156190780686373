import React, { useContext, useEffect, useState } from "react";
import * as serviceWorker from "../service-worker-registration";

export type SWContextData = {
  updateAvailable: boolean;
  registration?: ServiceWorkerRegistration;
};

const initialContext = { updateAvailable: false };

export const SWContext = React.createContext<SWContextData>(initialContext);

export const SWProvider = ({
  children,
  value,
}: {
  children: React.ReactElement;
  value: SWContextData;
}) => {
  return <SWContext.Provider value={value}>{children}</SWContext.Provider>;
};

export const useSW = () => {
  return useContext(SWContext);
};

export const withServiceWorker = <P extends object>(
  Component: React.ComponentType<P>
): React.FC<P> => {
  function WithServiceWorker(props: P) {
    const [data, setData] = useState<SWContextData>({ updateAvailable: false });
    const [, setWaitingWorker] = React.useState<ServiceWorker | null>(null);

    function onSWUpdate(registration: ServiceWorkerRegistration) {
      setWaitingWorker(registration.waiting);
      registration.waiting?.postMessage({ type: "SKIP_WAITING" });
      setData({ updateAvailable: true, registration });
      registration.update().then(() => console.info("PWA: Updated"));
      registration.unregister().then(() => console.info("PWA: Unregister"));
    }

    useEffect(() => {
      serviceWorker.register({ onUpdate: onSWUpdate });
    }, []);

    return (
      <SWProvider value={data}>
        <Component {...props} />
      </SWProvider>
    );
  }

  return WithServiceWorker;
};
