import { Adapter } from "@/@types";
import { Company, CreateCompanyPayload } from "@/@types/company";

export class CompanyService {
  constructor(private readonly http: Adapter) {}

  // @deprecated - Use legal entities
  async getInfo(companyDocumentNumber: string): Promise<Company> {
    return this.http
      .get(`/v1/companies/${companyDocumentNumber}`)
      .then((response) => response.data);
  }

  async verifyDocumentNumber(companyDocumentNumber: string): Promise<boolean> {
    return this.http
      .get(`/v1/companies/${companyDocumentNumber}/valid`)
      .then((response) => response.data.exists);
  }

  async createCompany(payload: CreateCompanyPayload) {
    return this.http
      .post(`/v1/companies`, payload)
      .then((response) => response.data);
  }

  async getCompanyProfile(companyId: string): Promise<Company> {
    return this.http
      .get(`/v1/companies/${companyId}/profile`)
      .then((response) => response.data);
  }

  async updateCompany(
    companyId: string,
    payload: Partial<Company>
  ): Promise<Company> {
    return this.http
      .patch(`/v1/companies/${companyId}`, payload)
      .then((response) => response.data);
  }
}
