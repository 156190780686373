import { Adapter } from "@/@types";
import { Company } from "@/@types/company";

import {
  ServiceInvoice,
  ActivateInvoiceIssuancePayload,
  IssueServiceInvoicePayload,
  InvoiceStatus,
  ServiceInvoiceAttachments,
  IssueProductInvoicePayload,
  ProductInvoice,
  ProductInvoiceAttachments,
  IssueCustomerInvoicePayload,
  CustomerInvoice,
} from "@/@types/invoices";

export class InvoiceService {
  constructor(private readonly http: Adapter) {}

  async issueServiceInvoice(
    payload: IssueServiceInvoicePayload
  ): Promise<Company> {
    return this.http.post(`/v1/service_invoices`, payload);
  }

  async issueProductInvoice(
    payload: IssueProductInvoicePayload
  ): Promise<Company> {
    return this.http.post(`/v1/product_invoices`, payload);
  }

  async issueCustomerInvoice(
    payload: IssueCustomerInvoicePayload
  ): Promise<Company> {
    return this.http.post(`/v1/customer_invoices`, payload);
  }

  async getProductInvoices(
    companyId: string,
    status?: InvoiceStatus
  ): Promise<ProductInvoice[]> {
    return this.http
      .get(`/v1/product_invoices`, {
        params: {
          companyId,
          status,
        },
      })
      .then((response) => response.data);
  }

  async getCustomerInvoices(
    companyId: string,
    status?: InvoiceStatus
  ): Promise<CustomerInvoice[]> {
    return this.http
      .get(`/v1/customer_invoices`, {
        params: {
          companyId,
          status,
        },
      })
      .then((response) => response.data);
  }

  async getServiceInvoices(
    companyId: string,
    status?: InvoiceStatus
  ): Promise<ServiceInvoice[]> {
    return this.http
      .get(`/v1/service_invoices`, {
        params: {
          companyId,
          status,
        },
      })
      .then((response) => response.data);
  }

  async getServiceInvoice(invoiceId: string): Promise<ServiceInvoice> {
    return this.http
      .get(`/v1/service_invoices/${invoiceId}`)
      .then((response) => response.data);
  }

  async getServiceAttachments(
    invoiceId: string
  ): Promise<ServiceInvoiceAttachments> {
    return this.http
      .get(`/v1/service_invoices/${invoiceId}/attachments`)
      .then((response) => response.data);
  }

  async getProductAttachments(
    invoiceId: string
  ): Promise<ProductInvoiceAttachments> {
    return this.http
      .get(`/v1/product_invoices/${invoiceId}/attachments`)
      .then((response) => response.data);
  }

  async activateInvoiceIssuance(
    payload: ActivateInvoiceIssuancePayload
  ): Promise<{ enabled: boolean }> {
    return this.http
      .post(
        `/v1/companies/${payload.companyId}/activate_invoice_issuance`,
        payload
      )
      .then((response) => response.data);
  }

  async deleteCustomerInvoice(invoiceId: string): Promise<CustomerInvoice> {
    return this.http.delete(`/v1/customer_invoices/${invoiceId}/cancel`);
  }

  async deleteServiceInvoice(invoiceId: string): Promise<ServiceInvoice> {
    return this.http.delete(`/v1/service_invoices/${invoiceId}/cancel`);
  }

  async deleteProductInvoice(invoiceId: string): Promise<ProductInvoice> {
    return this.http.delete(`/v1/product_invoices/${invoiceId}/cancel`);
  }
}
