import * as React from "react";
import { cva, VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";

const avatarVariants = cva(
  "flex flex-col items-center justify-center rounded-full",
  {
    variants: {
      variant: {
        default: "bg-blue-50 text-blue font-medium",
        green: "bg-success-foreground text-success fill-success font-medium",
        red: "bg-destructive-foreground text-destructive fill-destructive font-medium",
        muted: "bg-gray-100 text-gray-600 fill-gray-600 font-medium",
      },
      size: {
        default: "h-12 w-12 text-lg",
        small: "h-10 w-10 text-sm",
      },
    },
    defaultVariants: {
      variant: "default",
      size: "default",
    },
  }
);

export interface AvatarProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof avatarVariants> {}

const Avatar = React.forwardRef<HTMLDivElement, AvatarProps>(
  ({ className, size, variant, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(avatarVariants({ variant, size, className }))}
      {...props}
    />
  )
);
Avatar.displayName = "Avatar";

export { Avatar, avatarVariants };
