import { Adapter } from "@/@types";
import {
  Bill,
  BillConciliation,
  CreateEntriesPayload,
  Receivable,
  ReceivableConciliation,
} from "@/@types/entries";

export class EntriesService {
  constructor(private readonly http: Adapter) {}

  async markAsDoneEntries(
    companyId: string,
    entries: string[]
  ): Promise<{ bills: number; receivables: number }> {
    return this.http
      .patch(
        `/v1/companies/${companyId}/entries/mark_as_done`,
        {},
        {
          params: {
            entries,
          },
        }
      )
      .then((response) => response.data);
  }

  async undoMarkAsDoneEntries(
    companyId: string,
    entries: string[]
  ): Promise<{ bills: number; receivables: number }> {
    return this.http
      .patch(
        `/v1/companies/${companyId}/entries/mark_as_done/undo`,
        {},
        {
          params: {
            entries,
          },
        }
      )
      .then((response) => response.data);
  }

  async deleteEntries(
    companyId: string,
    entries: string[]
  ): Promise<{ bills: number; receivables: number }> {
    return this.http
      .delete(`/v1/companies/${companyId}/entries`, {
        params: {
          entries,
        },
      })
      .then((response) => response.data);
  }

  async undoDeleteEntries(
    companyId: string,
    entries: string[]
  ): Promise<{ bills: number; receivables: number }> {
    return this.http
      .patch(
        `/v1/companies/${companyId}/entries/delete/undo`,
        {},
        {
          params: {
            entries,
          },
        }
      )
      .then((response) => response.data);
  }

  async getEntriesSheet(companyId: string): Promise<any> {
    return this.http
      .get(`/v1/companies/${companyId}/entries/sheets`, {
        responseType: "blob",
      })
      .then((response) => response.data);
  }

  async uploadEntries(
    companyId: string,
    file: File
  ): Promise<{
    bills: BillConciliation[];
    receivables: ReceivableConciliation[];
    routing_number: string | null;
  }> {
    return this.http
      .postForm(`/v1/companies/${companyId}/entries/upload`, {
        file,
      })
      .then((response) => response.data);
  }

  async createEntries(
    companyId: string,
    payload: CreateEntriesPayload
  ): Promise<unknown> {
    return this.http
      .post(`/v1/companies/${companyId}/entries`, payload)
      .then((response) => response.data);
  }
}
