import { Adapter } from "@/@types";
import { BankAccount } from "@/@types/accounts";

export class AccountsService {
  constructor(private readonly http: Adapter) {}

  async createAccount(
    companyId: string,
    payload: Pick<
      BankAccount,
      "date_initial_balance" | "routing_number" | "kind"
    > & {
      initial_balance_amount_cents: string | number;
    }
  ): Promise<BankAccount> {
    return this.http
      .post(`/v1/companies/${companyId}/bank_accounts`, payload)
      .then((response) => response.data);
  }

  async updateAccount(
    companyId: string,
    bankAccountId: string,
    payload: Pick<
      BankAccount,
      "date_initial_balance" | "routing_number" | "kind"
    > & {
      initial_balance_amount_cents: string | number;
    }
  ): Promise<BankAccount> {
    return this.http
      .patch(
        `/v1/companies/${companyId}/bank_accounts/${bankAccountId}`,
        payload
      )
      .then((response) => response.data);
  }

  async archiveAccount(
    companyId: string,
    bankAccountId: string
  ): Promise<BankAccount> {
    return this.http
      .patch(
        `/v1/companies/${companyId}/bank_accounts/${bankAccountId}/archive`
      )
      .then((response) => response.data);
  }

  async getAccount(
    companyId: string,
    bankAccountId: string
  ): Promise<BankAccount> {
    return this.http
      .get(`/v1/companies/${companyId}/bank_accounts/${bankAccountId}`)
      .then((response) => response.data);
  }

  async getAccounts(companyId: string): Promise<BankAccount[]> {
    return this.http
      .get(`/v1/companies/${companyId}/bank_accounts`)
      .then((response) => response.data);
  }

  async getAccountsBalance(companyId: string): Promise<BankAccount[]> {
    return this.http
      .get(`/v1/companies/${companyId}/bank_accounts/balance`)
      .then((response) => response.data);
  }
}
