import React, { useContext, useEffect } from "react";
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from "@tanstack/react-query";
import { AnalyticsAdapter } from "./analytics.adapter";

export type ServiceProviderProps = {
  children: React.ReactNode;
  queryClient?: QueryClient;
};

export const analytics = new AnalyticsAdapter();

export const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      retry: false,
    },
    queries: {
      retry: false, // TODO: Enable only production
    },
  },
  queryCache: new QueryCache(),
  mutationCache: new MutationCache({
    onSuccess: (_data, _variables, _context, mutation) => {
      queryClient.invalidateQueries({
        queryKey: mutation.options.mutationKey,
      });
    },
  }),
});

analytics.init(import.meta.env.VITE_AMPLITUDE_API_KEY!);

export function createServicesProvider<I>(initialContext: I) {
  const ServicesContext = React.createContext<I>(initialContext);

  const ServicesProvider = (inProps: ServiceProviderProps) => {
    const { children } = inProps;

    return (
      <ServicesContext.Provider value={initialContext}>
        <QueryClientProvider client={inProps.queryClient || queryClient}>
          {children}
        </QueryClientProvider>
      </ServicesContext.Provider>
    );
  };

  const useServices = () => {
    return useContext(ServicesContext);
  };

  return {
    ServicesProvider,
    useServices,
  };
}
