import { toFloat } from "./to-float";

export enum BalanceType {
  CREDIT = "credit",
  DEBIT = "debit",
}

export function toDecimal(
  amount: number,
  type: BalanceType = BalanceType.CREDIT
) {
  return new Intl.NumberFormat("pt-BR", {
    style: "decimal",
    currency: "BRL",
    minimumFractionDigits: 2,
  }).format(toFloat(amount, type));
}
