import { useEffect } from "react";
import { useNavigate } from "react-router";

import { useAuthStore } from "@/modules/auth";
import { URL_COMPANY, URL_DASHBOARD, URL_LOGIN } from "@/constants/urls";
import { useCurrentCompany } from "@/modules/user";

import { PageLoader } from "./page-loader";

export default function Redirect() {
  const navigate = useNavigate();
  const { currentCompany, isSuccess } = useCurrentCompany();
  const { status, userId } = useAuthStore((state) => state);

  useEffect(() => {
    if ((status === "finished" || status === "error") && !userId) {
      navigate(URL_LOGIN);
    } else if (currentCompany?.id) {
      navigate(`${URL_COMPANY}/${currentCompany?.id}${URL_DASHBOARD}`);
    } else if (isSuccess) {
      navigate(`${URL_COMPANY}/unknown${URL_DASHBOARD}`);
    }
  }, [status, userId, currentCompany?.id, isSuccess]);

  return <PageLoader />;
}
