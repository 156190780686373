import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router";

import {
  URL_COMPANY,
  URL_DELETE,
  URL_LOGIN,
  URL_PIX,
  URL_SIGN_UP,
} from "@/constants/urls";
import { AuthComponent } from "@/components/layouts/wrapper";

import Redirect from "./redirect";
import { PageLoader } from "./page-loader";

const Login = lazy(() => import("./login/page"));
const VerifyOtp = lazy(() => import("./login/verify-otp/page"));
const DocumentNumber = lazy(() => import("./login/document-number/page"));

const InQueuePhase = lazy(() => import("./in-queue-phase/page"));
const UserData = lazy(() => import("./sign-up/user-data/page"));
const UserAccess = lazy(() => import("./sign-up/user-access/page"));
const UserVerifyOtp = lazy(() => import("./sign-up/verify-otp/page"));
const CompanyDocumentNumber = lazy(
  () => import("./sign-up/company-document-number/page")
);
const UserFeedbackPage = lazy(
  () => import("./sign-up/user-data/feedback/page")
);

const DashboardPage = lazy(() => import("./company/dashboard/page"));
const DashboardLayout = lazy(() => import("./company/dashboard/layout"));
const UserDocumentNumberPage = lazy(
  () => import("./company/dashboard/user-document-number/page")
);
const TaxDetailPageFromDashboard = lazy(
  () => import("./company/dashboard/tax/page")
);

const TaxesPage = lazy(() => import("./company/taxes/page"));
const TaxesLayout = lazy(() => import("./company/taxes/layout"));
const TaxDetailPage = lazy(() => import("./company/taxes/tax/page"));
const TaxDetailPixPage = lazy(() => import("./company/taxes/tax/pix/page"));

const ContactsPage = lazy(() => import("./company/contacts/page"));
const ContactsLayout = lazy(() => import("./company/contacts/layout"));
const ContactsUploadPage = lazy(() => import("./company/contacts/upload/page"));
const ContactDetailPage = lazy(() => import("./company/contacts/contact/page"));
const ContactDeletePage = lazy(
  () => import("./company/contacts/contact/delete/page")
);

const CreateContactLayout = lazy(
  () => import("./company/contacts/create/layout")
);
const CreateContactDocumentNumberPage = lazy(
  () => import("./company/contacts/create/document-number/page")
);
const CreateContactEmailAndPhonePage = lazy(
  () => import("./company/contacts/create/email-and-phone/page")
);
const CreateContactAddressPage = lazy(
  () => import("./company/contacts/create/address/page")
);

const ProfilePage = lazy(() => import("./company/profile/page"));
const ProfileLayout = lazy(() => import("./company/profile/layout"));

const CategoriesPage = lazy(() => import("./company/categories/page"));
const CategoriesLayout = lazy(() => import("./company/categories/layout"));
const CreateCategoryPage = lazy(
  () => import("./company/categories/create/page")
);
const CategoryDetailPage = lazy(
  () => import("./company/categories/category/page")
);

const SubscriptionsPage = lazy(() => import("./company/subscriptions/page"));
const SubscriptionsLayout = lazy(
  () => import("./company/subscriptions/layout")
);
const SubscribePage = lazy(
  () => import("./company/subscriptions/subscribe/page")
);
const SubscriptionsCheckoutPage = lazy(
  () => import("./company/subscriptions/checkout/page")
);

const ProductsPage = lazy(() => import("./company/products/page"));
const ProductsLayout = lazy(() => import("./company/products/layout"));
const CreateProductPage = lazy(() => import("./company/products/create/page"));
const ProductDetailPage = lazy(() => import("./company/products/product/page"));
const ProductDeletePage = lazy(
  () => import("./company/products/product/delete/page")
);

const CompanyServicesPage = lazy(
  () => import("./company/company-services/page")
);
const CompanyServicesLayout = lazy(
  () => import("./company/company-services/layout")
);
const CreateCompanyServicePage = lazy(
  () => import("./company/company-services/create/page")
);
const CompanyServiceDetailPage = lazy(
  () => import("./company/company-services/company-service/page")
);
const CompanyServiceDeletePage = lazy(
  () => import("./company/company-services/company-service/delete/page")
);

const CashFlowPage = lazy(() => import("./company/cash-flow/page"));
const CashFlowLayout = lazy(() => import("./company/cash-flow/layout"));

const CreateCategoryFromCashFlowPage = lazy(
  () => import("./company/cash-flow/categories/page")
);

const UploadEntriesPage = lazy(() => import("./company/cash-flow/upload/page"));

const CreateReceivablePage = lazy(
  () => import("./company/cash-flow/receivables/create/page")
);
const ReceivableDetailPage = lazy(
  () => import("./company/cash-flow/receivables/receivable/page")
);
const ReceivableDeletePage = lazy(
  () => import("./company/cash-flow/receivables/receivable/delete/page")
);
const ReceivableConciliationPage = lazy(
  () => import("./company/cash-flow/receivables/receivable/conciliation/page")
);
const ReceivableUploadPage = lazy(
  () => import("./company/cash-flow/receivables/receivable/upload/page")
);

const CreateBillPage = lazy(
  () => import("./company/cash-flow/bills/create/page")
);
const BillDetailPage = lazy(
  () => import("./company/cash-flow/bills/bill/page")
);
const BillDeletePage = lazy(
  () => import("./company/cash-flow/bills/bill/delete/page")
);
const BillConciliationPage = lazy(
  () => import("./company/cash-flow/bills/bill/conciliation/page")
);
const BillUploadPage = lazy(
  () => import("./company/cash-flow/bills/bill/upload/page")
);

const OpenFinancePage = lazy(
  () => import("./company/cash-flow/bank-accounts/open-finance/page")
);
const BankAccountDetailPage = lazy(
  () => import("./company/cash-flow/bank-accounts/bank-account/page")
);
const BankAccountArchivePage = lazy(
  () => import("./company/cash-flow/bank-accounts/bank-account/archive/page")
);
const CreateBankAccountLayout = lazy(
  () => import("./company/cash-flow/bank-accounts/create/layout")
);
const CreateBankAccountInstitutionPage = lazy(
  () => import("./company/cash-flow/bank-accounts/create/institution/page")
);
const CreateBankAccountConnectionPage = lazy(
  () => import("./company/cash-flow/bank-accounts/create/connection/page")
);
const CreateBankAccountDetailPage = lazy(
  () => import("./company/cash-flow/bank-accounts/create/detail/page")
);

const InvoicesPage = lazy(() => import("./company/invoices/page"));
const InvoicesLayout = lazy(() => import("./company/invoices/layout"));
const InvoiceDetailPage = lazy(() => import("./company/invoices/invoice/page"));
const InvoicesSettingsPage = lazy(
  () => import("./company/invoices/settings/page")
);
const InvoicesSettingsPendingPage = lazy(
  () => import("./company/invoices/settings/pending/page")
);
const InvoicesReplicatePage = lazy(
  () => import("./company/invoices/invoice/replicate/page")
);
const InvoicesDeletePage = lazy(
  () => import("./company/invoices/invoice/delete/page")
);
const InvoicesIssuePage = lazy(() => import("./company/invoices/issue/page"));

export function Router() {
  return (
    <BrowserRouter>
      <Suspense fallback={<PageLoader />}>
        <Routes>
          <Route index element={<Redirect />} />
          <Route path="in-queue-phase" element={<AuthComponent />}>
            <Route index element={<InQueuePhase />} />
          </Route>
          <Route path={URL_LOGIN} element={<AuthComponent />}>
            <Route index element={<Login />} />
            <Route path="verify-otp" element={<VerifyOtp />} />
            <Route path="document-number" element={<DocumentNumber />} />
          </Route>
          <Route path={URL_SIGN_UP} element={<AuthComponent />}>
            <Route path="user-data" element={<UserData />}>
              <Route path="feedback/:status" element={<UserFeedbackPage />} />
            </Route>
            <Route path="user-access" element={<UserAccess />} />
            <Route path="verify-otp" element={<UserVerifyOtp />} />
            <Route
              path="company-document-number"
              element={<CompanyDocumentNumber />}
            />
          </Route>
          <Route path={`${URL_COMPANY}/:companyId`}>
            <Route
              path="dashboard"
              element={
                <DashboardLayout>
                  <DashboardPage />
                </DashboardLayout>
              }
            >
              <Route
                path="user-document-number"
                element={<UserDocumentNumberPage />}
              />
              <Route
                path="tax/:taxId"
                element={<TaxDetailPageFromDashboard />}
              />
            </Route>
            <Route
              path="taxes"
              element={
                <TaxesLayout>
                  <TaxesPage />
                </TaxesLayout>
              }
            >
              <Route path=":taxId" element={<TaxDetailPage />} />
              <Route path={`:taxId${URL_PIX}`} element={<TaxDetailPixPage />} />
            </Route>
            <Route path="profile" element={<ProfileLayout />}>
              <Route index element={<ProfilePage />} />
            </Route>
            <Route
              path="contacts"
              element={
                <ContactsLayout>
                  <ContactsPage />
                </ContactsLayout>
              }
            >
              <Route path="upload" element={<ContactsUploadPage />} />
              <Route path="create" element={<CreateContactLayout />}>
                <Route
                  index
                  path="document-number"
                  element={<CreateContactDocumentNumberPage />}
                />
                <Route
                  path="email-and-phone"
                  element={<CreateContactEmailAndPhonePage />}
                />
                <Route path="address" element={<CreateContactAddressPage />} />
              </Route>
              <Route path=":contactId">
                <Route index element={<ContactDetailPage />} />
                <Route path="delete" element={<ContactDeletePage />} />
              </Route>
            </Route>
            <Route
              path="categories"
              element={
                <CategoriesLayout>
                  <CategoriesPage />
                </CategoriesLayout>
              }
            >
              <Route path="create" element={<CreateCategoryPage />} />
              <Route path=":categoryId" element={<CategoryDetailPage />} />
            </Route>
            <Route
              path="products"
              element={
                <ProductsLayout>
                  <ProductsPage />
                </ProductsLayout>
              }
            >
              <Route path="create" element={<CreateProductPage />} />
              <Route path=":productId">
                <Route index element={<ProductDetailPage />} />
                <Route path="delete" element={<ProductDeletePage />} />
              </Route>
            </Route>
            <Route
              path="services"
              element={
                <CompanyServicesLayout>
                  <CompanyServicesPage />
                </CompanyServicesLayout>
              }
            >
              <Route
                path=":companyServiceId"
                element={<CompanyServiceDetailPage />}
              />
              <Route
                path={`:companyServiceId${URL_DELETE}`}
                element={<CompanyServiceDeletePage />}
              />
              <Route path="create" element={<CreateCompanyServicePage />} />
            </Route>
            <Route path="subscriptions" element={<SubscriptionsLayout />}>
              <Route index element={<SubscriptionsPage />} />
              <Route path="subscribe" element={<SubscribePage />}>
                <Route
                  path="checkout"
                  element={<SubscriptionsCheckoutPage />}
                />
              </Route>
            </Route>
            <Route
              path="cash-flow"
              element={
                <CashFlowLayout>
                  <CashFlowPage />
                </CashFlowLayout>
              }
            >
              <Route
                path="categories"
                element={<CreateCategoryFromCashFlowPage />}
              />
              <Route path="upload" element={<UploadEntriesPage />} />
              <Route path="receivables">
                <Route path="create" element={<CreateReceivablePage />} />
                <Route path=":receivableId">
                  <Route index element={<ReceivableDetailPage />} />
                  <Route path="delete" element={<ReceivableDeletePage />} />
                  <Route
                    path="conciliation"
                    element={<ReceivableConciliationPage />}
                  />
                  <Route path="upload" element={<ReceivableUploadPage />} />
                </Route>
              </Route>
              <Route path="bills">
                <Route path="create" element={<CreateBillPage />} />
                <Route path=":billId">
                  <Route index element={<BillDetailPage />} />
                  <Route path="delete" element={<BillDeletePage />} />
                  <Route
                    path="conciliation"
                    element={<BillConciliationPage />}
                  />
                  <Route path="upload" element={<BillUploadPage />} />
                </Route>
              </Route>
              <Route path="bank-accounts">
                <Route path="open-finance" element={<OpenFinancePage />} />
                <Route path=":bankAccountId">
                  <Route index element={<BankAccountDetailPage />} />
                  <Route path="archive" element={<BankAccountArchivePage />} />
                </Route>
                <Route path="create" element={<CreateBankAccountLayout />}>
                  <Route
                    path="institution"
                    element={<CreateBankAccountInstitutionPage />}
                  />
                  <Route
                    path="connection"
                    element={<CreateBankAccountConnectionPage />}
                  />
                  <Route
                    path="detail"
                    element={<CreateBankAccountDetailPage />}
                  />
                </Route>
              </Route>
            </Route>
            <Route
              path="invoices"
              element={
                <InvoicesLayout>
                  <InvoicesPage />
                </InvoicesLayout>
              }
            >
              <Route path="pending" element={<InvoicesSettingsPendingPage />} />
              <Route path=":invoiceId">
                <Route index element={<InvoiceDetailPage />} />
                <Route path="replicate" element={<InvoicesReplicatePage />} />
                <Route path="delete" element={<InvoicesDeletePage />} />
              </Route>
            </Route>
            <Route
              path="invoices/settings"
              element={
                <InvoicesLayout>
                  <InvoicesSettingsPage />
                </InvoicesLayout>
              }
            />
            <Route
              path="invoices/issue"
              element={
                <InvoicesLayout>
                  <InvoicesIssuePage />
                </InvoicesLayout>
              }
            />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
}
