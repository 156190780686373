import { useMemo } from "react";
import { useParams } from "react-router";

import { useProfile } from "./use-profile";

export function useCurrentCompany() {
  const { user, isLoading, isSuccess } = useProfile();
  // TODO: Isso fere nossa arquitetura, precisamos que a info a empresa atual esteja dentro do zustand
  const { companyId } = useParams();

  const currentCompany = useMemo(() => {
    if (!companyId || companyId === "undefined") {
      return user?.companies[0];
    }

    const company = user?.companies.find((company) => company.id === companyId);

    return company;
  }, [user, companyId]);

  const companies = useMemo(() => user?.companies, [user?.companies]);

  return {
    companies,
    currentCompany,
    isLoading,
    isSuccess,
  };
}
