import { AxiosRequestConfig, AxiosResponseHeaders } from "axios";

export type AdapterConfig<D> = AxiosRequestConfig<D>;

export interface AdapterResponse<T = any, D = any> {
  data: T;
  status: number;
  statusText: string;
  headers: AxiosResponseHeaders;
  config: AxiosRequestConfig<D>;
  request?: any;
}

export abstract class Adapter {
  abstract request<T = any, R = AdapterResponse<T>, D = any>(
    config?: AdapterConfig<D>
  ): Promise<R>;
  abstract get<T = any, R = AdapterResponse<T>, D = any>(
    url: string,
    config?: AdapterConfig<D>
  ): Promise<R>;
  abstract put<T = any, R = AdapterResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AdapterConfig<D>
  ): Promise<R>;
  abstract post<T = any, R = AdapterResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AdapterConfig<D>
  ): Promise<R>;
  abstract postForm<T = any, R = AdapterResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AdapterConfig<D>
  ): Promise<R>;
  abstract delete<T = any, R = AdapterResponse<T>, D = any>(
    url: string,
    config?: AdapterConfig<D>
  ): Promise<R>;
  abstract patch<T = any, R = AdapterResponse<T>, D = any>(
    url: string,
    data?: D,
    config?: AdapterConfig<D>
  ): Promise<R>;
  abstract setInterceptorRequestHeaders<V, T>(
    onFulfilled?: (value: V) => T | Promise<T>,
    onRejected?: (error: any) => any
  ): number;
  abstract setInterceptorResponseHeaders<V, T>(
    onFulfilled?: (value: V) => T | Promise<T>,
    onRejected?: (error: any) => any
  ): number;
  abstract setDefaultsHeaders(
    defaults: Record<"Authorization" | string, any>
  ): void;
  abstract removeInterceptorRequest(id: number): void;
  abstract removeInterceptorResponse(id: number): void;
}
