import { Adapter } from "@/@types";
import { Bill, BillConciliation, UpdateBillPayload } from "@/@types/entries";
import { CreateBillPayload } from "@/@types/entries/bill/create-bill";
import { ConciliationBillPayload } from "@/@types/entries";

export class BillService {
  constructor(private readonly http: Adapter) {}

  async createBill(
    companyId: string,
    payload: CreateBillPayload
  ): Promise<Bill> {
    return this.http
      .post(`/v1/companies/${companyId}/bills`, payload)
      .then((response) => response.data);
  }

  async getConciliationBill(
    companyId: string,
    billId: string
  ): Promise<BillConciliation> {
    return this.http
      .get(`/v1/companies/${companyId}/bills/${billId}/conciliation`)
      .then((response) => response.data);
  }

  async conciliationBill(
    companyId: string,
    { bill_id, ...payload }: ConciliationBillPayload
  ): Promise<Bill> {
    return this.http
      .post(`/v1/companies/${companyId}/bills/${bill_id}/conciliation`, payload)
      .then((response) => response.data);
  }

  async undoConciliationBill(
    companyId: string,
    { bill_id, ...payload }: ConciliationBillPayload
  ): Promise<Bill> {
    return this.http
      .post(
        `/v1/companies/${companyId}/bills/${bill_id}/conciliation/undo`,
        payload
      )
      .then((response) => response.data);
  }

  async updateBill(
    companyId: string,
    { bill_id, ...rest }: UpdateBillPayload
  ): Promise<Bill> {
    return this.http
      .patch(`/v1/companies/${companyId}/bills/${bill_id}`, rest)
      .then((response) => response.data);
  }

  async getBill(companyId: string, billId: string): Promise<Bill> {
    return this.http
      .get(`/v1/companies/${companyId}/bills/${billId}`)
      .then((response) => response.data);
  }

  async getBills(
    companyId: string,
    start_date?: string,
    end_date?: string
  ): Promise<BillConciliation[]> {
    return this.http
      .get(`/v1/companies/${companyId}/bills`, {
        params: {
          start_date,
          end_date,
        },
      })
      .then((response) => response.data);
  }

  async deleteBill(companyId: string, billId: string): Promise<Bill> {
    return this.http
      .delete(`/v1/companies/${companyId}/bills/${billId}`)
      .then((response) => response.data);
  }
}
