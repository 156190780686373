import { Adapter } from "@/@types";
import {
  CreateProductPayload,
  Product,
  ProductClassification,
  Products,
} from "@/@types/products";

export class ProductsService {
  constructor(private readonly http: Adapter) {}

  async getClassifications(): Promise<ProductClassification[]> {
    return this.http
      .get(`/v1/product_classifications`)
      .then((response) => response.data);
  }

  async getProducts(companyId: string): Promise<Product[]> {
    return this.http
      .get(`/v1/companies/${companyId}/products`)
      .then((response) => response.data);
  }

  async getProductById(
    companyId: string,
    productId: string
  ): Promise<CreateProductPayload> {
    return this.http
      .get(`/v1/companies/${companyId}/products/${productId}`)
      .then((response) => response.data);
  }

  async createProduct(
    companyId: string,
    payload: CreateProductPayload
  ): Promise<Product> {
    return this.http
      .post(`/v1/companies/${companyId}/products`, payload)
      .then((response) => response.data);
  }

  async updateProduct(
    companyId: string,
    productId: string,
    payload: Partial<Product>
  ): Promise<Product> {
    return this.http
      .patch(`/v1/companies/${companyId}/products/${productId}`, payload)
      .then((response) => response.data);
  }

  async deleteProduct(
    companyId: string,
    productId: string
  ): Promise<CreateProductPayload> {
    return this.http
      .delete(`/v1/companies/${companyId}/products/${productId}`)
      .then((response) => response.data);
  }
}
