import * as React from "react";

import { cn } from "@/lib/utils";

export interface TextareaProps
  extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          `
            flex 
            min-h-[80px] 
            w-full 
            rounded-md 
            border 
            border-input 
            bg-background 
            px-3 
            py-2 
            text-sm 
            transition-all
            outline-none 
            placeholder:text-muted-foreground 
            focus-visible:border-blue
            disabled:cursor-not-allowed 
            disabled:opacity-50
            resize-none
          `,
          className
        )}
        ref={ref}
        {...props}
      />
    );
  }
);
Textarea.displayName = "Textarea";

export { Textarea };
