import { Adapter } from "@/@types";
import { UserProfile } from "@/@types/user";

export class UserService {
  constructor(private readonly http: Adapter) {}

  async updatePassword(password: string) {
    return this.http.patch("/v1/password", {
      password,
    });
  }

  async updateName(name: string) {
    return this.http.patch("/v1/name", {
      name,
    });
  }

  async updateDocumentNumber(natural_document_number: string) {
    return this.http.patch(`/v1/natural_document_number`, {
      natural_document_number,
    });
  }

  async getProfile(): Promise<UserProfile> {
    return this.http.get(`/v1/profile`).then((response) => response.data);
  }

  async updateProfile(payload: Partial<UserProfile>): Promise<UserProfile> {
    return this.http.patch(`/v1/profile`, payload);
  }
}
