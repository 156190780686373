"use client";

import React, { useCallback, useEffect, useRef } from "react";
import { useStore } from "zustand";

import { useService } from "@/services";
import { UserProfile } from "@/@types/user";

import {
  createAuthStore,
  initAuthStore,
  AuthStore,
} from "../stores/auth.store";

type AuthStoreProviderProps = {
  children: React.ReactNode;
};

export type AuthStoreApi = {
  getUser?: (token: string) => Promise<UserProfile | undefined>;
} & ReturnType<typeof createAuthStore>;

export const AuthStoreContext = React.createContext<AuthStoreApi | undefined>(
  undefined
);

export function AuthStoreProvider(inProps: AuthStoreProviderProps) {
  const { children } = inProps;

  const storeRef = useRef<AuthStoreApi>();
  const { auth, user: userService } = useService();

  if (!storeRef.current) {
    storeRef.current = createAuthStore(initAuthStore());
  }

  const getUser = useCallback(async () => {
    const state = storeRef.current?.getState();

    await auth.setAuthorizationHeader();
    state?.setStatus("loading");
    try {
      const user = await userService.getProfile();
      state?.setUserId(user.id);
      return user;
    } catch (error) {
      state?.setStatus("error");
    }
  }, [auth, userService]);

  useEffect(() => {
    async function init() {
      const state = storeRef.current?.getState();

      const accessToken = localStorage.getItem("@setfin:token");

      if (accessToken) {
        await getUser();
      } else {
        state?.setStatus("error");
      }
    }

    init();
  }, [getUser]);

  return (
    <AuthStoreContext.Provider value={{ ...storeRef.current, getUser }}>
      {children}
    </AuthStoreContext.Provider>
  );
}

export const useAuth = () => {
  const authStoreContext = React.useContext(AuthStoreContext);

  if (!authStoreContext) {
    throw new Error(`useAuthStore must be used within AuthStoreProvider`);
  }

  return authStoreContext;
};

export const useAuthStore = <T,>(selector: (store: AuthStore) => T): T => {
  const authStoreContext = React.useContext(AuthStoreContext);

  if (!authStoreContext) {
    throw new Error(`useAuthStore must be used within AuthStoreProvider`);
  }

  return useStore(authStoreContext, selector);
};
