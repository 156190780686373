export enum LegalPersonSituation {
  ATIVA = "ATIVA",
  INAPTA = "INAPTA",
  BAIXADA = "BAIXADA",
}

export type LegalPerson = {
  name: string;
  document_number: string;
  trade_name: string;
  situation: LegalPersonSituation;
  verified?: boolean;
};
