"use client";

import * as React from "react";
import * as SwitchPrimitives from "@radix-ui/react-switch";

import { cn } from "@/lib/utils";
import { CircleNotch } from "@phosphor-icons/react";

const Switch = React.forwardRef<
  React.ElementRef<typeof SwitchPrimitives.Root>,
  { loading?: boolean } & React.ComponentPropsWithoutRef<
    typeof SwitchPrimitives.Root
  >
>(({ className, loading = false, ...props }, ref) => (
  <SwitchPrimitives.Root
    className={cn(
      `
        peer 
        inline-flex 
        h-6 
        w-11 
        shrink-0 
        cursor-pointer 
        items-center 
        rounded-full 
        border-2 
        border-transparent 
        transition-colors 
        focus-visible:outline-none 
        focus-visible:ring-2 
        focus-visible:ring-ring 
        focus-visible:ring-offset-2 
        focus-visible:ring-offset-background 
        disabled:cursor-not-allowed 
        disabled:opacity-50 
        data-[state=checked]:bg-blue-700 
        data-[state=checked]:dark:bg-blue-300
        data-[state=unchecked]:bg-input
      `,
      className
    )}
    {...props}
    disabled={props.disabled || loading}
    ref={ref}
  >
    <SwitchPrimitives.Thumb
      className={cn(
        "pointer-events-none block h-5 w-5 rounded-full bg-background shadow-lg ring-0 transition-transform data-[state=checked]:translate-x-5 data-[state=unchecked]:translate-x-0"
      )}
    >
      {loading && (
        <CircleNotch
          size={20}
          className="animate-spin fill-blue"
          aria-label="Carregando"
        />
      )}
    </SwitchPrimitives.Thumb>
  </SwitchPrimitives.Root>
));
Switch.displayName = SwitchPrimitives.Root.displayName;

export { Switch };
