import { BalanceType } from "./to-decimal";

export function convertToSignedNumber(amount: number, type: BalanceType) {
  if (type === BalanceType.DEBIT) {
    return amount * -1;
  }

  return amount;
}

export function toFloat(
  amount: number,
  type: BalanceType = BalanceType.CREDIT
) {
  const convertedAmount = convertToSignedNumber(amount, type);

  return convertedAmount / 100;
}
