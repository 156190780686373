import { Adapter } from "@/@types";
import { NaturalPerson } from "@/@types/person";

export class PersonService {
  constructor(private readonly http: Adapter) {}

  async getNaturalPerson(documentNumber: string): Promise<NaturalPerson> {
    return this.http
      .get(`/v1/persons/natural/${documentNumber}`)
      .then((response) => response.data);
  }
}
