import { useAuthStore } from "@/modules/auth";
import { useService } from "@/services";
import { useQuery } from "@tanstack/react-query";

export function useProfile() {
  const { user: userService } = useService();
  const { userId } = useAuthStore((state) => state);

  const { data: user, ...rest } = useQuery({
    enabled: !!userId,
    queryKey: ["user_profile"],
    queryFn: () => userService.getProfile(),
  });

  return {
    user,
    ...rest,
  };
}
