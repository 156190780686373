import { Adapter } from "@/@types";
import { CompanyService, MunicipalService } from "@/@types/services";

export class ServicesService {
  constructor(private readonly http: Adapter) {}

  async getMunicipalServices(companyId?: string): Promise<MunicipalService[]> {
    return this.http
      .get(`/v1/municipal_services`, {
        params: {
          companyId,
        },
      })
      .then((response) => response.data);
  }

  async getCompanyService(
    companyId: string,
    companyServiceId: string
  ): Promise<CompanyService> {
    return this.http
      .get(`/v1/companies/${companyId}/services/${companyServiceId}`)
      .then((response) => response.data);
  }

  async getCompanyServices(companyId: string): Promise<CompanyService[]> {
    return this.http
      .get(`/v1/companies/${companyId}/services`)
      .then((response) => response.data);
  }

  async createCompanyService(
    companyId: string,
    payload: Partial<CompanyService>
  ): Promise<CompanyService> {
    return this.http.post(`/v1/companies/${companyId}/services`, payload);
  }

  async deleteCompanyService(
    companyId: string,
    companyServiceId: string
  ): Promise<void> {
    return this.http
      .delete(`/v1/companies/${companyId}/services/${companyServiceId}`)
      .then((response) => response.data);
  }

  async updateCompanyService(
    companyId: string,
    companyServiceId: string,
    payload: Partial<CompanyService>
  ): Promise<CompanyService> {
    return this.http
      .patch(`/v1/companies/${companyId}/services/${companyServiceId}`, payload)
      .then((response) => response.data);
  }
}
