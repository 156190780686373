import { Adapter } from "@/@types";

import { Tax } from "@/@types/tax";

export class TaxService {
  constructor(private readonly http: Adapter) {}

  async getTax(taxId: string, companyId: string): Promise<Tax> {
    return this.http
      .get(`/v1/taxes/${companyId}/detail/${taxId}`)
      .then((response) => response.data);
  }

  async getTaxes(companyId: string, year: string): Promise<Tax[]> {
    return this.http
      .get(`/v1/taxes/${companyId}`, {
        params: {
          year,
        },
      })
      .then((response) => response.data);
  }

  async issueTax(taxId: string, companyId: string): Promise<Tax> {
    return this.http
      .post(`/v1/taxes/${companyId}/issue/${taxId}`)
      .then((response) => response.data);
  }
}
