import { BalanceType } from "./to-decimal";
import { toFloat } from "./to-float";

export function toCurrency(
  amount: number,
  type: BalanceType = BalanceType.CREDIT
) {
  return new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
  }).format(toFloat(amount, type));
}
