import { Adapter } from "@/@types";
import { Contact } from "@/@types/contact";

export class ContactService {
  constructor(private readonly http: Adapter) {}

  async createContact(payload: Partial<Contact>): Promise<Contact> {
    return this.http
      .post(`/v1/contacts/`, payload)
      .then((response) => response.data);
  }

  async updateContact(
    contactId: string,
    payload: Partial<Contact>
  ): Promise<Contact> {
    return this.http.patch(`/v1/contacts/${contactId}`, payload);
  }

  async importContacts(companyId: string, file: File): Promise<Contact[]> {
    return this.http.postForm(`/v1/contacts/${companyId}/import`, {
      file,
    });
  }

  async getContact(contactId: string): Promise<Contact> {
    return this.http
      .get(`/v1/contacts/${contactId}`)
      .then((response) => response.data);
  }

  async getContacts(companyId: string): Promise<Contact[]> {
    return this.http
      .get(`/v1/contacts?companyId=${companyId}`)
      .then((response) => response.data);
  }

  async deleteContact(contactId: string): Promise<Contact> {
    return this.http.delete(`/v1/contacts/${contactId}`);
  }
}
