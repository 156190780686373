import { ApiAdapter, createServicesProvider } from "@/adapters";

import { AuthService } from "./auth.service";
import { UserService } from "./user.service";
import { PersonService } from "./person.service";
import { CompanyService } from "./company.service";
import { ContactService } from "./contact.service";
import { TutorialService } from "./tutorial.service";
import { TaxService } from "./tax.service";
import { ServicesService } from "./services.service";
import { ProductsService } from "./products.service";
import { InvoiceService } from "./invoice.service";
import { AddressService } from "./address.service";
import { CategoryService } from "./category.service";
import { CertificateService } from "./certificate.service";
import { InstitutionsService } from "./institutions.service";
import { AccountsService } from "./accounts.service";
import { BillService } from "./bill.service";
import { ReceivableService } from "./receivable.service";
import { AttachmentService } from "./attachment.service";
import { EntriesService } from "./entries.service";
import { CheckoutService } from "./checkout.service";
import { NaturalEntityService } from "./natural-entity.service";
import { SubscriptionService } from "./subscription.service";

export type ServiceContextData = {
  address: AddressService;
  auth: AuthService;
  user: UserService;
  person: PersonService;
  contact: ContactService;
  company: CompanyService;
  naturalEntity: NaturalEntityService;
  category: CategoryService;
  certificate: CertificateService;
  tutorial: TutorialService;
  tax: TaxService;
  product: ProductsService;
  service: ServicesService;
  invoice: InvoiceService;
  institutions: InstitutionsService;
  account: AccountsService;
  bill: BillService;
  receivable: ReceivableService;
  attachment: AttachmentService;
  entries: EntriesService;
  checkout: CheckoutService;
  subscription: SubscriptionService;
};

const apiAdapter = new ApiAdapter(import.meta.env.VITE_CONNECT_API_URL!);

function configService(): ServiceContextData {
  return {
    attachment: new AttachmentService(apiAdapter),
    address: new AddressService(apiAdapter),
    tax: new TaxService(apiAdapter),
    auth: new AuthService(apiAdapter),
    user: new UserService(apiAdapter),
    person: new PersonService(apiAdapter),
    contact: new ContactService(apiAdapter),
    category: new CategoryService(apiAdapter),
    company: new CompanyService(apiAdapter),
    certificate: new CertificateService(apiAdapter),
    tutorial: new TutorialService(apiAdapter),
    product: new ProductsService(apiAdapter),
    service: new ServicesService(apiAdapter),
    invoice: new InvoiceService(apiAdapter),
    institutions: new InstitutionsService(apiAdapter),
    account: new AccountsService(apiAdapter),
    bill: new BillService(apiAdapter),
    receivable: new ReceivableService(apiAdapter),
    entries: new EntriesService(apiAdapter),
    checkout: new CheckoutService(apiAdapter),
    naturalEntity: new NaturalEntityService(apiAdapter),
    subscription: new SubscriptionService(apiAdapter),
  };
}

export const { useServices: useService, ServicesProvider } =
  createServicesProvider(configService());
