"use client";

import * as React from "react";
import {
  Check,
  Info,
  ExclamationMark,
  X,
  CircleNotch,
} from "@phosphor-icons/react";
import * as ToastPrimitives from "@radix-ui/react-toast";
import { cva, type VariantProps } from "class-variance-authority";

import { cn } from "@/lib/utils";
import { buttonVariants } from "./button";

const ToastProvider = ToastPrimitives.Provider;

const ToastViewport = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Viewport>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Viewport>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Viewport
    ref={ref}
    className={cn(
      `
        fixed 
        top-0 
        z-[100] 
        flex 
        max-h-screen 
        w-full 
        flex-col-reverse 
        p-4 
        sm:bottom-0 
        sm:right-0 
        sm:top-auto 
        sm:flex-col 
        md:max-w-[420px]
      `,
      className
    )}
    {...props}
  />
));
ToastViewport.displayName = ToastPrimitives.Viewport.displayName;

const toastVariants = cva(
  `
    group 
    pointer-events-auto 
    relative 
    flex 
    items-center
    w-full 
    gap-4 
    overflow-hidden 
    rounded-md 
    border 
    p-6 
    pr-8 
    shadow-lg 
    transition-all
    bg-background
    data-[swipe=cancel]:translate-x-0 
    data-[swipe=end]:translate-x-[var(--radix-toast-swipe-end-x)] 
    data-[swipe=move]:translate-x-[var(--radix-toast-swipe-move-x)] 
    data-[swipe=move]:transition-none data-[state=open]:animate-in 
    data-[state=closed]:animate-out data-[swipe=end]:animate-out 
    data-[state=closed]:fade-out-80 data-[state=closed]:slide-out-to-right-full 
    data-[state=open]:slide-in-from-top-full data-[state=open]:sm:slide-in-from-bottom-full
  `,
  {
    variants: {
      variant: {
        default: "default group",
        warning: "warning group",
        destructive: "destructive group",
        success: "success group",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  }
);

const Toast = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Root>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Root> &
    VariantProps<typeof toastVariants>
>(({ className, variant, children, ...props }, ref) => {
  return (
    <ToastPrimitives.Root
      ref={ref}
      className={cn(toastVariants({ variant }), className)}
      {...props}
    >
      <ToastIcon variant={variant} />
      {children}
    </ToastPrimitives.Root>
  );
});
Toast.displayName = ToastPrimitives.Root.displayName;

const ToastIcon = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & VariantProps<typeof toastVariants>
>(({ className, variant }, ref) => {
  const initialClassName = "flex items-center justify-center rounded-full";

  return (
    <div
      className={cn(
        className,
        initialClassName,
        "min-w-10 min-h-10",
        "group-[.destructive]:bg-destructive-foreground",
        "group-[.success]:bg-success-foreground",
        "group-[.warning]:bg-warning-foreground",
        "group-[.default]:bg-blue-50"
      )}
    >
      <div
        ref={ref}
        className={cn(
          initialClassName,
          "min-h-6 min-w-6 rounded-full",
          "group-[.destructive]:bg-destructive",
          "group-[.success]:bg-success",
          "group-[.warning]:bg-warning",
          "group-[.default]:bg-blue-700"
        )}
      >
        {variant === "destructive" && (
          <X size={14} className="fill-white" weight="bold" />
        )}
        {variant === "success" && (
          <Check size={14} className="fill-white" weight="bold" />
        )}
        {variant === "warning" && (
          <ExclamationMark size={14} className="fill-white" weight="bold" />
        )}
        {variant === "default" && (
          <Info size={14} className="fill-white" weight="bold" />
        )}
      </div>
    </div>
  );
});
ToastIcon.displayName = "ToastIcon";

const ToastAction = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Action>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Action> & {
    loading?: boolean;
  }
>(({ className, children, loading, disabled, ...props }, ref) => (
  <ToastPrimitives.Action
    ref={ref}
    disabled={loading || disabled}
    className={cn(
      buttonVariants({ variant: "outline", size: "small" }),
      className
    )}
    {...props}
  >
    {loading ? (
      <div>
        <CircleNotch
          size={20}
          aria-label="Carregando"
          className="animate-spin"
        />
      </div>
    ) : (
      children
    )}
  </ToastPrimitives.Action>
));
ToastAction.displayName = ToastPrimitives.Action.displayName;

const ToastTitle = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Title>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Title>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Title
    ref={ref}
    className={cn("font-medium text-primary", className)}
    {...props}
  />
));
ToastTitle.displayName = ToastPrimitives.Title.displayName;

const ToastDescription = React.forwardRef<
  React.ElementRef<typeof ToastPrimitives.Description>,
  React.ComponentPropsWithoutRef<typeof ToastPrimitives.Description>
>(({ className, ...props }, ref) => (
  <ToastPrimitives.Description
    ref={ref}
    className={cn("text-sm text-muted-foreground", className)}
    {...props}
  />
));
ToastDescription.displayName = ToastPrimitives.Description.displayName;

type ToastProps = React.ComponentPropsWithoutRef<typeof Toast>;

type ToastActionElement = React.ReactElement<typeof ToastAction>;

export {
  type ToastProps,
  type ToastActionElement,
  ToastProvider,
  ToastViewport,
  Toast,
  ToastTitle,
  ToastDescription,
  ToastAction,
};
