import { Adapter, AdapterConfig, AdapterResponse } from "@types";
import axios, { AxiosInstance, HeadersDefaults } from "axios";
import qs from "querystring";

export class ApiAdapter extends Adapter {
  private instance: AxiosInstance;

  constructor(baseUrl: string) {
    super();
    if (baseUrl === null || baseUrl === undefined) {
      throw new Error("baseUrl is missing!");
    }
    this.instance = axios.create({
      baseURL: baseUrl,
      paramsSerializer: (params) => {
        return qs.stringify(params);
      },
    });
  }

  request<T = any, R = AdapterResponse<T>, D = any>(
    config: AdapterConfig<D>
  ): Promise<R> {
    return this.instance(config);
  }

  delete<T = any, R = AdapterResponse<T>, D = any>(
    path: string,
    config?: AdapterConfig<D>
  ): Promise<R> {
    return this.instance.delete(path, config);
  }

  get<T = any, R = AdapterResponse<T>, D = any>(
    path: string,
    config?: AdapterConfig<D>
  ): Promise<R> {
    return this.instance.get(path, config);
  }

  patch<T = any, R = AdapterResponse<T>, D = any>(
    path: string,
    data: T,
    config?: AdapterConfig<D>
  ): Promise<R> {
    return this.instance.patch(path, data, config as any);
  }

  post<T = any, R = AdapterResponse<T>, D = any>(
    path: string,
    data: T,
    config?: AdapterConfig<D>
  ): Promise<R> {
    return this.instance.post(path, data, config as any);
  }

  postForm<T = any, R = AdapterResponse<T>, D = any>(
    path: string,
    data: T,
    config?: AdapterConfig<D>
  ): Promise<R> {
    return this.instance.postForm(path, data, config as any);
  }

  put<T = any, R = AdapterResponse<T>, D = any>(
    path: string,
    data: T,
    config?: AdapterConfig<D>
  ): Promise<R> {
    return this.instance.put(path, data, config as any);
  }

  removeInterceptorRequest(id: number): void {
    return this.instance.interceptors.request.eject(id);
  }

  removeInterceptorResponse(id: number): void {
    return this.instance.interceptors.response.eject(id);
  }

  setDefaultsHeaders(defaults: Record<"Authorization" | string, any>) {
    this.instance.defaults.headers.common = defaults;
  }

  setInterceptorRequestHeaders(
    onFulfilled?: (value: any) => any | Promise<any>,
    onRejected?: (error: any) => any
  ): number {
    return this.instance.interceptors.request.use(onFulfilled, onRejected);
  }

  setInterceptorResponseHeaders(
    onFulfilled?: (value: any) => any | Promise<any>,
    onRejected?: (error: any) => any
  ): number {
    return this.instance.interceptors.response.use(onFulfilled, onRejected);
  }
}
